import request from "../request";

const urls = {
  walletList: "/wallet/walletList",
  orderDetails: "/payments/orderDetails",
  caseTransactions: "/payments/caseTransaction",
  walletTransaction: "/wallet/transaction",
  walletOutstanding: "/wallet/outstanding",
  generatePaymentOtpURL: "/wallet/generatePaymentOtp",
  verifyOtpAndMakePaymentURL: "/wallet/verifyOtpAndMakePayment",
  generatePaymentLinkForUnsettledCases: "/payments/generatePaymentLinkForUnsettledCases",
};

export const getAllWallets = (query?: string) => {
  const response = request("GET", `${urls.walletList}${query}`);
  return response;
};

export const getPaymentsFromOrderId = async (query?: string) => {
  const response = request("GET", `${urls.orderDetails}${query}`);
  return response;
};

export const getTransactionsFroOrderId = async (query?: string) => {
  const response = request("GET", `${urls.caseTransactions}${query}`);
  return response;
};

export const getTransactionsForWallet = async (query?: string) => {
  const response = request("GET", `${urls.walletTransaction}${query}`);
  return response;
};

export const getOutstandingForWallet = async (query?: string) => {
  const response = request("GET", `${urls.walletOutstanding}${query}`);
  return response;
};

export const generatePaymentOtp = (payload?: any) => {
  const response = request("POST", `${urls.generatePaymentOtpURL}`, payload);
  return response;
};

export const verifyOtpAndMakePayment = (payload?: any) => {
  const response = request("POST", `${urls.verifyOtpAndMakePaymentURL}`, payload);
  return response;
};

export const linkForUnsettledCases = (payload: any) => {
  const response = request("POST", `${urls.generatePaymentLinkForUnsettledCases}`, payload);
  return response;
};
