import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import { IAddons, IAuditFormData, IAuditResponse, ICaseResponseObject, ICreateCasePayload, IDocumentUpload } from "../../config/types";
import {
  cancelOrder,
  classifyOrder,
  createCase,
  dispatchCase,
  fetchAuditData,
  getAllCase,
  getCase,
  getSaathiPricing,
  moveScheduleOrderToUnfulfilled,
  orderPricing,
  overrideDefaults,
  patientCaseHistory,
  removeDocuments,
  saveAuditData,
  sendUploadDocumentLink,
  updateCase,
  uploadDocuments,
} from "../../api/service/case";
import usePaginationData, { IPaginationMeta } from "../action/usePagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFiltersStore } from "../../store/FIlterStore";
import {
  extractQueryParams,
  getQueryFromObj,
  keyExtraction,
  objectToQueryString,
  objectToQueryStringOmitEmpty,
  payloadValidationCheck,
  removeNullAndUndefiendOrEmtpyStringfromObject,
  showErrorToast,
  showSuccessToast,
} from "../../utils";
import dayjs from "../../components/atom/dayjs";
import { useAppStore } from "../../store/AppStore";
import { useCreateCaseStore } from "../../store/CreateCaseStore";
import { getProperty, setProperty } from "dot-prop";
import { AMBULANCE_MAPPER_KEY, AUDIT_SUBMIT_KEY, AUTO_DISPATCH_ALLOWED_KEY_BTH, AUTO_DISPATCH_ALLOWED_KEY_BTP, DynamicFormData, PLATFORM_OBJ } from "../../config/constant";
import useCaseFilter from "./useCaseFilter";
import useFeature from "@hooks/action/useFeature";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import usePriceAction from "@store/action-hook/usePriceAction";
import { version } from "../../../package.json";
import { useSourceData } from "@hooks/api/useAppApi";
import useSyncMutation from "@hooks/action/useRedMutation";
import { debounce, every, find, get, has, mapValues } from "lodash";
import { useOrder } from "@hooks/api/useSave";

type ResponseType = any;

interface IResponse {
  data: ICaseResponseObject[];
  meta: IPaginationMeta;
  rest: UseQueryResult;
  goNext: () => void;
  goPrevious: () => void;
  setPage: (index: number) => void;
}

const GlobalStartDate = Math.floor(new Date(dayjs().subtract(1, "days")?.toISOString())?.getTime());
const GlobalEndDate = Math.floor(new Date(dayjs()?.toISOString())?.getTime());

const useCancelOrder = () => {
  const { mutateAsync: _cancelOrder, ...dispatchStatus } = useMutation(cancelOrder);
  const cancelOrderAsync = debounce(_cancelOrder, 100);
  const orderId = useRedStore(state => state.order.orderId);

  const cancelNow = async (arr: string[], onSuccess: any) => {
    await cancelOrderAsync(
      { orderId: orderId, reason: arr?.join(",") },
      {
        onSuccess: (data: any) => {
          onSuccess && onSuccess(data);
        },
      }
    );
  };
  return { cancelNow, ...dispatchStatus };
};

const useForceDispatch = () => {
  const { mutateAsync: changeOrderState, ...dispatchStatus } = useMutation(moveScheduleOrderToUnfulfilled);
  const changeOrder = debounce(changeOrderState, 100);
  const orderId = useRedStore(state => state.order.orderId);

  const changeNow = async (onSuccess: any) => {
    await changeOrder(
      { orderId: orderId, cancelScheduleReason: "Immediate Dispatch" },
      {
        onSuccess: (data: any) => {
          onSuccess && onSuccess(data);
        },
      }
    );
  };
  return { changeNow, ...dispatchStatus };
};

const useGetAllCase = (): IResponse => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParam = queryParams.get("page");
  const city = queryParams.get("city") || "HYD";
  const urlStartDate = queryParams.get("startDate");
  const urlEndDate = queryParams.get("endDate");

  const filters = useFiltersStore(state => state.selectedFilter);
  // const loginUser = useAppStore((state) => state.loginUser)
  const { meta, goNext, setPage, goPrevious, setupPaginationState } = usePaginationData();
  const [data, setData] = useState([]);
  const pageNo = pageParam ? parseInt(pageParam) : meta.pageIndex;

  const startDate = urlStartDate ?? GlobalStartDate;
  const endDate = urlEndDate ?? GlobalEndDate;

  const payload: any = {
    limit: meta.limit,
    page: pageNo,
    sortType: "desc",
    startDate,
    endDate,
    city,
    ...filters,
  };

  if (city === "all") {
    delete payload["city"];
  }

  if (queryParams.get("queryString")) {
    payload["queryString"] = queryParams.get("queryString");
  }

  const onSuccess = (response: AxiosResponse<ResponseType>) => {
    const { data } = response;
    if (data?.data && data.data?.length > 0) {
      // TODO: need to modify it to class based instance
      setData(data.data);
      setupPaginationState(data?.meta?.totalRecords, pageNo);
    } else {
      setData([]);
      setupPaginationState(0, 0);
    }
  };

  // get from url
  const obj = extractQueryParams(window.location.href);
  const query = getQueryFromObj({ ...payload, ...obj });

  const { ...rest } = useQuery(["All-Case"], () => getAllCase(query), {
    // enabled: !!payload.city,
    onSuccess: onSuccess,
  });

  return { data, meta, rest, goPrevious, setPage, goNext };
};

const useGetAllCaseForPilot = ({ pilotId }: { pilotId: string }): IResponse => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParam = queryParams.get("page");
  const city = queryParams.get("city") || "HYD";

  // const loginUser = useAppStore((state) => state.loginUser)
  const { meta, goNext, setPage, goPrevious, setupPaginationState } = usePaginationData(0, 5);
  const [data, setData] = useState([]);
  const pageNo = pageParam ? parseInt(pageParam) : meta.pageIndex;

  const payload: any = {
    limit: meta.limit,
    page: pageNo,
    sortType: "desc",
    city,
    pilotId,
  };

  if (city === "all") {
    delete payload["city"];
  }

  if (queryParams.get("queryString")) {
    payload["queryString"] = queryParams.get("queryString");
  }

  const onSuccess = (response: AxiosResponse<ResponseType>) => {
    const { data } = response;
    if (data?.data && data.data?.length > 0) {
      // TODO: need to modify it to class based instance
      setData(data.data);
      setupPaginationState(data?.meta?.totalRecords, pageNo);
    } else {
      setData([]);
      setupPaginationState(0, 0);
    }
  };

  const query = getQueryFromObj(payload);

  const { ...rest } = useQuery([query, query], () => getAllCase(query), {
    // enabled: !!payload.city,
    onSuccess: onSuccess,
  });

  return { data, meta, rest, goPrevious, setPage, goNext };
};

const useGetCase = ({ orderId, onSuccess }: { orderId?: string; onSuccess?: any }) => {
  const _updateOrder = useRedStore(state => state._updateOrder);
  const { convertToBooking } = useOrder();
  const navigate = useNavigate();

  const { populateLocalState } = useOrderAction();
  const { data, ...rest } = useSourceData();

  useEffect(() => {}, [data]);

  const updateAndNavigate = (response: any) => {
    console.log(response);
    const amount = response?.paymentDetails?.data?.amount || 0;

    if (response?.bookingType !== "BOOKING" && !!amount) {
      convertToBooking({
        onSuccess() {
          if (response?.orderId) {
            showSuccessToast("Convert it to Booking.");
            navigate(`/case-history/${response?.orderId}/case-overview`);
          } else {
            showErrorToast("No Order Id Found.");
            navigate(`/`);
          }
        },
      });
    }
  };

  const ojb = useQuery([`case-id-${orderId}`], () => getCase(orderId), {
    enabled: !!orderId,
    onSuccess: (data: any) => {
      if (data?.data?.data) {
        const response = data?.data?.data;
        // TODO - Remove: if user pay some amount and the case is not in booking then convert it booking and navigate to case-history page
        updateAndNavigate(response);
        _updateOrder(response);
        populateLocalState(response);
      }
      onSuccess && onSuccess(data);
    },
    refetchOnWindowFocus: false,
  });
  return { ...ojb, isFetching: ojb?.isFetching || rest?.isFetching, isLoading: ojb?.isLoading || rest?.isLoading };
};

const useSyncPickup = ({ orderId, onSuccess }: { orderId?: string; onSuccess?: any }) => {
  const _updateWaypoint1 = useRedStore(state => state._updateWaypoint1);
  const localWaypoint1Address: any = useRedStore(state => state.localOrderChanges?.waypoints[0]);

  const ojb = useQuery([`oid-case-id-${orderId}`], () => getCase(orderId), {
    enabled: !!orderId && !localWaypoint1Address?.address?.location,
    onSuccess: (data: any) => {
      if (data?.data?.data) {
        const response = data?.data?.data;
        if (!localWaypoint1Address?.address?.location && response?.waypoints && response?.waypoints?.length > 0 && response?.waypoints[0]?.address) {
          _updateWaypoint1(response?.waypoints[0]);
        }
      }
    },
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  });
  return { ...ojb, isFetching: ojb?.isFetching, isLoading: ojb?.isLoading };
};

const payloadKey = [
  // "bookingType",
  "generatedBy",
  "mobile",
  "originSource",
  "requestedBy",
  "serviceType",
  // "dispatchType",
  // "requestedFor",
  // "waypoints",
  // "orderClassification",
  // "distance",
  "city",
  "attributedTo",
  "crmOrderId",
];

const templatePayloadKey = [
  // "bookingType",
  "generatedBy",
  "mobile",
  "originSource",
  "requestedBy",
  "serviceType",
  // "dispatchType",
  // "requestedFor",
  // "waypoints",
  "attributedTo",
  "crmOrderId",
  "city",
  // "orderClassification",
  // "distance",
];

//

const useCreateCase = ({ onSuccess }: any) => {
  const loginUser = useRedStore(state => state.user);
  const [patientProfile, patientWaypoints, patientMeta] = useRedStore(state => [state.patientProfile, state.patientWaypoints, state.patientMeta]);

  const { ...sourceInfo } = useSourceData();
  const data: any = useRedStore(state => state.source);
  const cityCode = data?.find((itm: any) => itm?.branchId === loginUser?.dedicatedToClientId)?.cityCode;

  const { mutateAsync, ...rest } = useMutation(createCase, {
    onSuccess,
  });

  useEffect(() => {}, [loginUser]);

  const defaultPayloadOptions: any = {
    bookingType: "LEAD",
    originSource: { source: loginUser.dedicatedToClientId, ...PLATFORM_OBJ },
    generatedBy: loginUser.email,
  };

  if (patientProfile?.name) {
    defaultPayloadOptions["requestedFor"] = patientProfile;
  }

  if (patientWaypoints && patientWaypoints?.length > 1 && has(patientWaypoints[0], "geo") && has(patientWaypoints[1], "geo")) {
    defaultPayloadOptions["waypoints"] = patientWaypoints;
  }

  if (patientMeta && has(patientMeta, "orderClassification")) {
    defaultPayloadOptions["orderClassification"] = patientMeta?.orderClassification;
  }

  // if (patientMeta && has(patientMeta, "city")) {
  //   defaultPayloadOptions["city"] = patientMeta?.city;
  // }

  defaultPayloadOptions["city"] = cityCode ? cityCode : patientMeta?.city || loginUser?.city;

  // if (patientMeta && has(patientMeta, "distance")) {
  //   defaultPayloadOptions["distance"] = patientMeta?.distance;
  // }

  const createCaseAsync = (data: any, isTemplateMode?: any) => {
    if (!loginUser.dedicatedToClientId) {
      showErrorToast("Source is not define");
      throw Error("Source is not define.");
    }

    // City get overridden by Duplicate city.
    const _payload: any = {
      ...defaultPayloadOptions,
      ...data,
      mobile: data?.requestedBy.mobile,
    };

    const payload = keyExtraction(_payload, isTemplateMode ? templatePayloadKey : payloadKey);
    mutateAsync(payload);
  };

  return { createCaseAsync, ...rest };
};

const _pricingPayloadGenerator = (currentCase: any, loginUser: any, caseType?: any) => {
  const priceQuery: any = {
    pickupLat: getProperty(currentCase, "waypoints[0].geo.lat"),
    pickupLng: getProperty(currentCase, "waypoints[0].geo.long"),
    dropoffLat: getProperty(currentCase, "waypoints[1].geo.lat"),
    dropoffLng: getProperty(currentCase, "waypoints[1].geo.long"),

    city: currentCase.selectedCity?.value || loginUser?.city,
    casetype: caseType,
    sourceId: getProperty(currentCase, "selectedOrigin") || getProperty(currentCase, "originSource.source") || loginUser?.dedicatedToClientId,
  };

  if (getProperty(currentCase, "entityRequired[0].subtype")) {
    priceQuery["vehicle"] = getProperty(currentCase, "entityRequired[0].subtype");
  }

  if (getProperty(currentCase, "isCovidCase")) {
    priceQuery["isCovidCase"] = getProperty(currentCase, "isCovidCase");
  }

  const isReadyToQuery = Object.values(priceQuery).every(itm => !!itm);

  return [isReadyToQuery, priceQuery];
};

const useClassifyOrder = () => {
  const currentCase = useRedStore(state => state.localOrderChanges);

  const { __updateCaseTypeAndClassification } = useOrderAction();
  const { mutateAsync, ...rest } = useSyncMutation(classifyOrder, {
    onSuccess: (data: any) => {
      const caseType = data.data?.data?.caseType;

      if (!caseType) {
        showErrorToast("Please Choose valid Pickup & Drop.");
        return;
      }
      __updateCaseTypeAndClassification(caseType);
    },
  });

  const defaultPayloadOptions = {
    originSource: getProperty(currentCase, "originSource"),
    waypoints: getProperty(currentCase, "waypoints"),
  };

  const execute = async () => {
    const data = await mutateAsync(defaultPayloadOptions);
    const orderClassification = data?.data?.data?.caseType;

    if (!orderClassification) {
      showErrorToast("Unable to find Order classification data.");
      throw Error("Unable to find Order classification data.");
    }
    return orderClassification;
  };

  return { execute };
};

const INVALID_DOC_KEY = ["BHMS", "BAMS"];

function dataModerator(data: any) {
  const response = JSON.parse(JSON.stringify(data));
  try {
    const { data: responseData } = response;
    if (responseData?.data && Object.keys(responseData.data).length > 1) {
      const addons = responseData.data.addons?.map((itm: any) => {
        if (itm?.id === "300_DOC") {
          itm.subtypes = itm.subtypes?.filter((sub: any) => !INVALID_DOC_KEY.includes(sub.key));
        }
        return itm;
      });
      responseData.data.addons = addons;
    }
  } catch (err) {
    console.log("Data is not valid");
  }
  return response;
}

const useOrderPricing = () => {
  const loginUser = useRedStore(state => state.user);
  const currentCase = useRedStore(state => state.localOrderChanges);
  const order = useRedStore(state => state.order);
  const { __initiatePricing } = usePriceAction();

  const [priceQueryString, setPriceQueryString] = useState("");
  const [isReadyForPricingCall, setIsReadyForPricingCall] = useState(false);

  const fetchPricePayloadSetup = async (orderClassification: string) => {
    const [isReadyToQuery, priceQuery] = _pricingPayloadGenerator(currentCase, loginUser, orderClassification);
    if (isReadyToQuery) {
      setIsReadyForPricingCall(true);
      setPriceQueryString(objectToQueryString(priceQuery));
    }
  };

  const onFetchPrice = (data: any) => {
    console.log("Pricing data: ", data);
    if (data?.data?.data && Object.keys(data.data.data).length > 1) {
      const response = data.data.data;
      __initiatePricing(response);
    }
  };

  const { ...rest2 } = useQuery([`${JSON.stringify(priceQueryString)}`, priceQueryString], () => orderPricing(priceQueryString), {
    enabled: isReadyForPricingCall,
    onSuccess: onFetchPrice,
    refetchOnWindowFocus: false,
    select: dataModerator,
  });

  const execute = (classification?: string) => {
    let orderClassification = classification;
    if (!orderClassification) {
      orderClassification = currentCase?.orderClassification ? currentCase?.orderClassification : order?.orderClassification;
    }

    if (orderClassification && currentCase?.isLamaDamaSelected) {
      orderClassification = "LAMA/DAMA";
    }
    fetchPricePayloadSetup(orderClassification);
  };

  return { execute, isLoading: rest2?.isLoading };
};

const useOrderPricingHook = () => {
  const loginUser = useRedStore(state => state.user);

  // const currentCase = useRedStore(state => state.order);
  const { __updateCaseTypeAndClassification } = useOrderAction();
  const { __initiatePricing } = usePriceAction();

  const [priceQueryString, setPriceQueryString] = useState("");
  const [isReadyForPricingCall, setIsReadyForPricingCall] = useState(false);

  const fetchPricePayloadSetup = (orderClassification: string, currentCase: any) => {
    const [isReadyToQuery, priceQuery] = _pricingPayloadGenerator(currentCase, loginUser, orderClassification);
    if (isReadyToQuery) {
      setIsReadyForPricingCall(true);
      setPriceQueryString(objectToQueryString(priceQuery));
    }
  };

  // Get Classification order.
  const { mutateAsync, ...rest } = useSyncMutation(classifyOrder, {
    onSuccess: (data: any, _: any, payload: any) => {
      const caseType = data.data?.data?.caseType;
      console.log("eieiieiei: ", payload);
      if (!caseType) {
        showErrorToast("Please Choose valid Pickup & Drop.");
        return;
      }
      __updateCaseTypeAndClassification(caseType);
    },
  });

  const onFetchPrice = (data: any) => {
    console.log("Pricing data: ", data);
    if (data?.data?.data && Object.keys(data.data.data).length > 1) {
      const response = data.data.data;
      __initiatePricing(response);
    }
  };

  const { ...rest2 } = useQuery([`${JSON.stringify(priceQueryString)}`, priceQueryString], () => orderPricing(priceQueryString), {
    enabled: isReadyForPricingCall,
    onSuccess: onFetchPrice,
    refetchOnWindowFocus: false,
    select: dataModerator,
  });

  const fetchClassificationAndFetchPrice = debounce(mutateAsync, 300);

  const execute = async (currentCase: any) => {
    let orderClassification = currentCase?.orderClassification;
    // 1. check if you have the orderClassification data or not.
    if (currentCase?.orderClassification) {
      if (currentCase?.isLamaDamaSelected) {
        orderClassification = "LAMA/DAMA";
      } else {
        orderClassification = currentCase?.orderClassification;
      }
      // makeOrderUpdate(currentCase?.isLamaDamaSelected ? "LAMA/DAMA" : orderClassification);
      fetchPricePayloadSetup(orderClassification, currentCase);
    } else {
      const defaultPayloadOptions = {
        originSource: getProperty(currentCase, "originSource"),
        waypoints: getProperty(currentCase, "waypoints"),
      };

      if (!defaultPayloadOptions?.originSource || !defaultPayloadOptions?.waypoints) {
        return null;
      }
      // TODO: need to wait for this case type and then make prcicing api call.
      const caseType: any = await fetchClassificationAndFetchPrice(defaultPayloadOptions, currentCase);
      console.log(caseType?.data?.data?.caseType);
      fetchPricePayloadSetup(caseType?.data?.data?.caseType, currentCase);
    }
  };

  return { execute, isLoading: rest2?.isLoading };
};

const usePricingHook = () => {
  const loginUser = useRedStore(state => state.user);

  const currentCase = useRedStore(state => state.localOrderChanges);
  const { __updateCaseTypeAndClassification } = useOrderAction();
  // const setCurrentCaseMulti = useCreateCaseStore(state => state.setCurrentCaseMulti);
  // const setCaseProps = useCreateCaseStore(state => state.setCaseProps);
  const { __initiatePricing } = usePriceAction();
  const { updateOrderClassification } = useOrder();

  const [priceQueryString, setPriceQueryString] = useState("");
  const [isReadyForPricingCall, setIsReadyForPricingCall] = useState(false);

  const makeOrderUpdate = (orderClassification: string) => {
    updateOrderClassification({ orderClassification: orderClassification || "" });
  };

  const fetchPricePayloadSetup = async (orderClassification: string) => {
    const [isReadyToQuery, priceQuery] = _pricingPayloadGenerator(currentCase, loginUser, orderClassification);
    if (isReadyToQuery) {
      setIsReadyForPricingCall(true);
      setPriceQueryString(objectToQueryString(priceQuery));
    }
  };

  const { mutateAsync, ...rest } = useSyncMutation(classifyOrder, {
    onSuccess: (data: any) => {
      const caseType = data.data?.data?.caseType;
      if (!caseType) {
        showErrorToast("Please Choose valid Pickup & Drop.");
        return;
      }
      __updateCaseTypeAndClassification(caseType);
      fetchPricePayloadSetup(caseType);
    },
  });

  const prepAddonsData = (currentCase: any, payload: any, setProperty: any, serverAddons: any) => {
    const addonsMap = serverAddons.reduce((a: any, b: any) => {
      return { ...a, [b.id]: b };
    }, {});

    const _addons = currentCase?.addons?.map((addon: any) => (addonsMap[addon.id] ? { ...addonsMap[addon.id], quantity: addon.quantity } : false))?.filter((itm: any) => !!itm);

    const _serverAddons = serverAddons.map((addon: any) => {
      const currentAddon = _addons.find((itm: any) => itm.id == addon.id);
      return currentAddon?.id ? { ...addon, quantity: currentAddon?.quantity || 0 } : addon;
    });

    setProperty(payload, "addons", _addons);
    setProperty(payload, "serverAddons", _serverAddons);
  };

  const onFetchPrice = (data: any) => {
    console.log("Pricing data: ", data);
    if (data?.data?.data && Object.keys(data.data.data).length > 1) {
      const response = data.data.data;
      __initiatePricing(response);
    }
  };

  const { ...rest2 } = useQuery([`${JSON.stringify(priceQueryString)}`, priceQueryString], () => orderPricing(priceQueryString), {
    enabled: isReadyForPricingCall,
    onSuccess: onFetchPrice,
    refetchOnWindowFocus: false,
  });

  const defaultPayloadOptions = {
    originSource: getProperty(currentCase, "originSource"),
    waypoints: getProperty(currentCase, "waypoints"),
  };

  const fetchClassificationAndFetchPrice = debounce(mutateAsync, 300);
  const execute = () => {
    let orderClassification = "";
    // 1. check if you have the orderClassification data or not.
    if (currentCase?.orderClassification) {
      if (currentCase?.isLamaDamaSelected) {
        orderClassification = "LAMA/DAMA";
      } else {
        orderClassification = currentCase?.orderClassification;
      }
      // makeOrderUpdate(currentCase?.isLamaDamaSelected ? "LAMA/DAMA" : orderClassification);
      fetchPricePayloadSetup(orderClassification);
    } else {
      fetchClassificationAndFetchPrice(defaultPayloadOptions);
    }
  };

  return { execute, isLoading: rest?.isLoading || rest2?.isLoading };
};

const prepMedicalReasons = (currentCase: any, payload: any, setProperty: any) => {
  const reason = (getProperty(currentCase, "requestedFor.medicalIssue.reason") || [])?.map((itm: any) => itm.value);

  setProperty(payload, "requestedFor.medicalIssue.reason", reason);
};

const prepGender = (currentCase: any, payload: any, setProperty: any) => {
  const gender: any = getProperty(currentCase, "requestedFor.gender") || "";
  setProperty(payload, "requestedFor.gender", gender.value);
};

const prepAddons = (currentCase: any, payload: any, setProperty: any) => {
  const _addons: any = getProperty(currentCase, "addons") || [];
  if (Array.isArray(_addons) && _addons?.length > 0) {
    setProperty(
      payload,
      "addons",
      _addons?.map((itm: any) => ({ type: itm.type, price: itm.price, quantity: itm.quantity, id: itm.id, name: itm.name }))
    );
  }
};

const useDispatch = () => {
  const currentCase = useCreateCaseStore(state => state.changes);
  // need to check feature
  const { loginUser }: any = useAppStore();
  const { injectPayload } = useFeature();

  const { mutateAsync, ...rest } = useMutation(dispatchCase, {
    onSuccess: (data: any) => {
      console.log("dispatch: ", data);
    },
  });

  const defaultPayloadOptions = {
    originSource: { source: currentCase.originSource },
    dispatchBy: loginUser?.email,
  };

  const dispatchCurrentCase = () => {
    const payload: any = {
      ...defaultPayloadOptions,
    };

    const keys = currentCase?.billToClient ? AUTO_DISPATCH_ALLOWED_KEY_BTH : AUTO_DISPATCH_ALLOWED_KEY_BTP;

    keys.forEach(itm => {
      payload[itm] = currentCase[itm];
    });

    prepMedicalReasons(currentCase, payload, setProperty);
    prepGender(currentCase, payload, setProperty);
    if (currentCase?.addons?.length > 0) {
      prepAddons(currentCase, payload, setProperty);
    }

    payload["assignmentType"] = "AUTO";
    payload["bookingType"] = "BOOKING";

    injectPayload("dispatch-api", payload);

    mutateAsync(payload);
  };

  return { dispatchCurrentCase, ...rest };
};

interface IUseCaseUpdate {
  onSuccess?: (data: any) => void;
}

interface IFnCb {
  options?: object;
  onSuccess?: (data: any) => void;
}

const useCaseUpdate = (params?: IUseCaseUpdate) => {
  const currentCase = useCreateCaseStore(state => state.changes);
  const { loginUser }: any = useAppStore();
  const { injectPayload } = useFeature();

  const { mutateAsync, ...rest } = useMutation(updateCase, {
    onSuccess: (data: any) => (params?.onSuccess ? params?.onSuccess(data) : console.log("Update.")),
  });
  const { mutateAsync: overrideDefaultsUpdate, ...overrideDefaultsState } = useMutation(overrideDefaults);

  const platform = currentCase?.originSource?.platform || PLATFORM_OBJ.platform;
  const version = currentCase?.originSource?.version || PLATFORM_OBJ.version;

  const payload: any = { source: currentCase.originSource, platform };

  if (platform === "HM-App") {
    payload["version"] = version;
  }

  const defaultPayloadOptions = {
    originSource: payload,
  };

  const generatePayload = () => {
    const payload: any = {
      ...defaultPayloadOptions,
    };

    const keys = currentCase?.billToClient ? AUTO_DISPATCH_ALLOWED_KEY_BTH : AUTO_DISPATCH_ALLOWED_KEY_BTP;

    keys.forEach(itm => {
      payload[itm] = currentCase[itm];
    });

    prepMedicalReasons(currentCase, payload, setProperty);
    prepGender(currentCase, payload, setProperty);

    if (currentCase?.addons?.length > 0) {
      prepAddons(currentCase, payload, setProperty);
    }

    injectPayload("dispatch-api", payload);
    removeNullAndUndefiendOrEmtpyStringfromObject(payload);

    return payload;
  };

  const saveAsEnquiry = async ({ options, onSuccess }: IFnCb) => {
    let payload: any = generatePayload();
    payload["bookingType"] = "ENQUIRY";
    // Inject options
    payload = { ...payload, ...(options && { ...options }) };

    // if (currentCase?.isOverridenPrice) {
    //   await overrideAndUpdateCase({
    //     onSuccess: () => {
    //       mutateAsync(payload, {
    //         onSuccess: onSuccess || params?.onSuccess || (() => console.log("Update.")),
    //       });
    //     },
    //   });
    // } else {
    //   await mutateAsync(payload, {
    //     onSuccess: onSuccess || params?.onSuccess || (() => console.log("Update.")),
    //   });
    // }
    if (currentCase?.isOverridenPrice) {
      await overrideAndUpdateCase({});
    }
    await mutateAsync(payload, {
      onSuccess: onSuccess || params?.onSuccess || (() => console.log("Update.")),
    });
  };

  const updateCurrentCaseData = async ({ options, onSuccess }: IFnCb) => {
    const payload = { ...generatePayload(), ...(options && { ...options }) };

    if (currentCase?.isOverridenPrice) {
      await overrideAndUpdateCase({});
    }

    await mutateAsync(payload, {
      onSuccess: onSuccess || params?.onSuccess || (() => console.log("Update.")),
    });
  };

  const updateSingleProps = (options: any, onSuccess?: any) => {
    const payload = JSON.parse(JSON.stringify(options));

    injectPayload("dispatch-api", payload);
    removeNullAndUndefiendOrEmtpyStringfromObject(payload);

    mutateAsync(payload, {
      onSuccess: onSuccess || params?.onSuccess || (() => console.log("Update.")),
    });
  };

  const overrideAndUpdateCase = ({ onSuccess }: any) => {
    const overridenType = currentCase?.billToClient === "BTC" ? "BILL_TO_CLIENT" : "BILL_TO_PATIENT";
    const payload = { orderId: currentCase?.orderId, overridenPrice: currentCase?.fareAgreed, overridenType, overridenComments: currentCase?.overridenComments };

    return overrideDefaultsUpdate(payload, {
      onSuccess: () => {
        showSuccessToast("Successfully overriden the price.");
        onSuccess && onSuccess();
      },
    });
  };

  return { saveAsEnquiry, updateCurrentCaseData, updateSingleProps, overrideAndUpdateCase, ...rest };
};

const useMyCaseAnalytic = () => {
  const { userAnalytics } = useCaseFilter();
  // const query = userAnalytics();
  const filter = useRedStore(state => state.filter);
  const filterObj = { startDate: filter?.startDate?.value, endDate: filter?.endDate?.value, page: 0, limit: 3000 };
  const query = objectToQueryStringOmitEmpty(filterObj);

  const _updateAnalytics = useRedStore(state => state._updateAnalytics);
  const _updateAnalyticsOrderMetrics = useRedStore(state => state._updateAnalyticsOrderMetrics);
  const _updateAnalyticsLeadEnquiry = useRedStore(state => state._updateAnalyticsLeadEnquiry);
  const _updateAnalyticsIncompleteMetrics = useRedStore(state => state._updateAnalyticsIncompleteMetrics);

  const analyticsData = (data: ICaseResponseObject[]) => {
    const payload = {
      orderMetrics: {
        created: 0,
        completed: 0,
        incomplete: 0,
        scheduled: 0,
      },
      leadEnquiry: {
        leads: 0,
        enquiries: 0,
      },
      incompleteMetrics: {
        cancelled: 0,
        unfulfilled: 0,
        pendingPayment: 0,
      },
    };

    const calculateOrderMetrics = () => {
      setProperty(payload, "orderMetrics.created", data?.length);
      setProperty(payload, "orderMetrics.completed", data?.filter(itm => itm.orderStatus === "fulfilled")?.length);
      setProperty(payload, "orderMetrics.incomplete", data?.filter(itm => !["fulfilled", "cancelled"].includes(itm.orderStatus || "unknown"))?.length);
      setProperty(payload, "orderMetrics.scheduled", data?.filter(itm => !!itm.scheduledDdtm)?.length);
    };

    const calculateLeadEnquiry = () => {
      setProperty(payload, "leadEnquiry.enquiries", data?.filter(itm => itm.bookingType === "ENQUIRY")?.length);
      setProperty(payload, "leadEnquiry.leads", data?.filter(itm => itm.bookingType === "LEAD")?.length);
    };

    const calculateIncompleteMetrics = () => {
      setProperty(payload, "incompleteMetrics.cancelled", data?.filter(itm => itm.orderStatus === "cancelled")?.length);
      setProperty(payload, "incompleteMetrics.unfulfilled", data?.filter(itm => itm.orderStatus === "unfulfilled")?.length);
    };

    calculateOrderMetrics();
    calculateLeadEnquiry();
    calculateIncompleteMetrics();

    _updateAnalytics(payload);
  };

  return useQuery([`case-id-${query}`], () => getAllCase(query), {
    onSuccess: (data: any) => {
      if (data?.data?.data) {
        analyticsData(data?.data?.data);
      }
    },
    refetchOnWindowFocus: false,
  });
};

const useInfiniteCase = (defaultQuery: object) => {
  const loginUser = useRedStore(state => state.user);
  const { getFilterQueryString } = useCaseFilter();
  const query = getFilterQueryString(defaultQuery);

  // useEffect(() => {}, [loginUser?.dedicatedToClientId]);

  return useInfiniteQuery(
    [`my-case-${query}`],
    async ({ pageParam = 0 }) => {
      const data = await getAllCase(getFilterQueryString(removeNullAndUndefiendOrEmtpyStringfromObject({ page: pageParam, ...defaultQuery })));
      return data.data;
    },
    {
      // enabled: !!loginUser?.dedicatedToClientId,
      getNextPageParam: (lastPage: any, pages) => {
        if (lastPage?.data.length >= 50) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );
};

const useInfiniteCaseHistory = (defaultQuery: object) => {
  const loginUser = useRedStore(state => state.user);
  const { getFilterQueryString } = useCaseFilter();
  const query = getFilterQueryString(defaultQuery);

  // const _initiatePatientProfile = useRedStore(state => state._initiatePatientProfile);
  const _updateTemptingCaseId = useRedStore(state => state._updateTemptingCaseId);

  const updateUserProfile = (data: any) => {
    const obj = find(data, ["bookingType", "BOOKING"]);
    if (obj) {
      // const reqFor = obj?.requestedFor;
      // const waypoints = obj?.waypoints;
      // const meta = { orderClassification: obj?.orderClassification, city: obj?.city, distance: obj?.distance };
      // _initiatePatientProfile(reqFor, waypoints, meta);
      _updateTemptingCaseId(obj.orderId);
    }
  };

  const db = debounce(updateUserProfile, 300);

  return useInfiniteQuery(
    [`patient-case-${query}`],
    async ({ pageParam = 0 }) => {
      const data: any = await patientCaseHistory(getFilterQueryString({ page: pageParam, ...defaultQuery }));
      db(data?.data?.data);
      return data.data;
    },
    {
      // enabled: !!loginUser?.dedicatedToClientId,
      getNextPageParam: (lastPage: any, pages) => {
        if (lastPage?.data.length >= 50) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );
};

const useMultiLevelCaseUpdate = () => {
  // const { mutateAsync, ...rest } = useMutation(updateCase, {
  //   onSuccess: (data: any) => {
  //     // invalidate cache
  //   },
  // });

  // const payload = {
  //   originSource: { source: currentCase.originSource, platform: "HM-APP" },
  // }

  useCreateCaseStore.subscribe((state, prevState) => {});

  const updateWaypoints = (waypoints: any) => {
    const payload = { waypoints };
    // mutateAsync(payload);
  };
};

const useAssetUpload = () => {
  const { mutateAsync, ...rest } = useSyncMutation(uploadDocuments);

  const uploadDocument = async (file: IDocumentUpload) => {
    await mutateAsync(file);
  };

  return { uploadDocument, ...rest };
};

const useAssetRemove = () => {
  const { mutateAsync, ...rest } = useSyncMutation(removeDocuments);

  const removeDocument = async (orderId: string, id: number) => {
    await mutateAsync({ orderId, id });
  };

  return { removeDocument, ...rest };
};

const useAudit = () => {
  const { mutateAsync, ...rest } = useSyncMutation(saveAuditData);
  const user = useRedStore(state => state.user);

  // IAuditFormData
  const saveAudit = async (payload: any) => {
    const finalPayload: any = Object.keys(payload).reduce(
      (acc: any, key: any) => {
        if (AUDIT_SUBMIT_KEY.includes(key)) {
          acc[key] = payload[key];
        } else if (key?.includes("ambulanceImage")) {
          if (!acc?.documents) {
            acc.documents = [];
          } else {
            if (payload[key]) {
              acc.documents = [...acc.documents, payload[key]];
            }
          }
        }
        return acc;
      },
      {
        updatedBy: user?.email,
      }
    );

    if (finalPayload.isRegistrationSame !== "Yes") {
      finalPayload.isRegistrationSame = payload._isRegistrationSame;
    }

    return await mutateAsync(finalPayload);
  };

  return { saveAudit, ...rest };
};

const useSendDocumentLink = () => {
  const { mutateAsync, ...rest } = useSyncMutation(sendUploadDocumentLink);
  const user = useRedStore(state => state.user);
  const order = useRedStore(state => state.order);

  const payload: any = {
    orderId: order?.orderId || "",
    // "recipients": [8001981993],
    // "userType": "PILOT",
    requestedBy: user?.email,
    requestedSource: user?.dedicatedToClientId,
  };

  // IAuditFormData
  const sendDocumentLink = async (recipientNumber: any, userType: any, onSuccess: any) => {
    payload["recipients"] = [recipientNumber];
    payload["userType"] = userType;

    try {
      payloadValidationCheck(payload, async () => {
        return await mutateAsync(payload, { onSuccess });
      });
    } catch (err) {
      showErrorToast("Invalid payload, HM musth have a Client id and Email.");
    }
  };

  return { sendDocumentLink, ...rest };
};

const useAuditData = ({ orderId }: { orderId: string }) => {
  const extractedKey = [...DynamicFormData, { key: "documents", label: "Documents" }];
  const parseResponse = (res: any) => {
    const formArray: any = [];
    try {
      const data = res.data.data;

      for (const key in data) {
        const templateKey = find(extractedKey, ["key", key]);
        if (templateKey) {
          formArray.push({ label: templateKey?.label, value: data[key] });
        }
      }
    } catch (err) {
      showErrorToast("Unable to parse Audit Data.");
    }
    return formArray;
  };

  return useQuery([`audit-for-${orderId}`], () => fetchAuditData(orderId), {
    enabled: !!orderId,
    select: parseResponse,
  });
};

const useSatthiPricing = () => {
  const [localOrderChanges, _setConstToOwner, _setSaatiPricingList, distance, selectedAddons, billToClient] = useRedStore(state => [
    state.localOrderChanges,
    state._setConstToOwner,
    state._setSaatiPricingList,
    state.price.distance,
    state.selectedAddons,
    state.price.billToClient,
  ]);

  const { mutateAsync, ...rest } = useSyncMutation(getSaathiPricing, {
    onSuccess: (data: any) => {
      const response = data?.data?.data;
      const sb: any = getProperty(localOrderChanges, "entityRequired[0].subtype", "");
      const subtype = AMBULANCE_MAPPER_KEY[sb];
      if (subtype && response?.pricingList && Array.isArray(response.pricingList)) {
        const selectedVehicle = find(response?.pricingList, ["fleetVariant", subtype]);

        if (selectedVehicle?.costToOwner) {
          _setConstToOwner(selectedVehicle?.costToOwner);
          _setSaatiPricingList(response.pricingList);
        } else {
          if (!billToClient) {
            showErrorToast("Cost To Owner not found.");
          }
        }
      } else {
        showErrorToast("No Pricing List / No Entity found.");
      }
    },
  });

  const addonModifier = (addon: IAddons) => {
    return {
      quantity: addon.quantity,
      price: addon.price,
      id: addon?.id,
      type: addon.name,
    };
  };

  const execute = async () => {
    const { city } = localOrderChanges;
    const payload = {
      city: city,
      distance: distance ? parseInt(distance) : 0,
      country: "IN",
      addons: selectedAddons?.map(addonModifier),
    };
    console.log("addons:::: ", payload);
    if (every(payload)) {
      await mutateAsync(payload);
    }
  };

  return { execute, ...rest };
};

export {
  useSendDocumentLink,
  useGetAllCase,
  useGetAllCaseForPilot,
  useGetCase,
  useCreateCase,
  usePricingHook,
  useDispatch,
  useCaseUpdate,
  useInfiniteCase,
  useInfiniteCaseHistory,
  useMultiLevelCaseUpdate,
  useMyCaseAnalytic,
  useClassifyOrder,
  useAssetUpload,
  useAssetRemove,
  useAudit,
  useAuditData,
  useCancelOrder,
  useForceDispatch,
  useSyncPickup,
  useOrderPricing,
  useOrderPricingHook,
  useSatthiPricing,
};
