// export const ACCEPTED_EVENT = ["pos_payment_failed", "pos_payment_successful", "pos_payment_init"];
export const ACCEPTED_EVENT = ["Pine_Labs_POS_Payment_Failed", "PAYMENT_SUCCESS", "Pine_Labs_POS_Payment_Successful", "Pine_Labs_POS_Payment_Initiated"];

export const POS_EVENT_MAPS = {
  pos_payment_failed: "Pine_Labs_POS_Payment_Failed",
  pos_payment_successful: "PAYMENT_SUCCESS",
  pos_payment_init: "Pine_Labs_POS_Payment_Initiated",
};

export const PAYMENT_MODES = ["card", "upi"];

export const POS_ENABLED_SOURCES = ["HYDH006B001"];

export const POS_TIMEOUT = 6 * 60; // 6 MIN
