import { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery, UseQueryResult } from "react-query";
import { AxiosResponse } from "axios";
import { ICaseResponseObject } from "../../config/types";
import usePaginationData, { IPaginationMeta } from "../action/usePagination";
import { useLocation } from "react-router-dom";
import { useFiltersStore } from "../../store/FIlterStore";
import { getQueryFromObj, logger, objectToQueryString, showErrorToast, voidFunction } from "../../utils";

import {
  generatePaymentOtp,
  getAllWallets,
  getOutstandingForWallet,
  getPaymentsFromOrderId,
  getTransactionsForWallet,
  getTransactionsFroOrderId,
  linkForUnsettledCases,
  verifyOtpAndMakePayment,
} from "../../api/service/wallet";
import { useUrlParams } from "../action/useUtils";
import dayjs from "dayjs";
import { useRedStore } from "@store/store";
import useCaseFilter from "@hooks/api/useCaseFilter";
import useSyncMutation from "@hooks/action/useRedMutation";
import { MAPPING_FOR_USER_ROLES_FOR_ONLINE_PAY } from "@config/constant";

type ResponseType = any;

interface IResponse {
  data: ICaseResponseObject[];
  meta: IPaginationMeta;
  rest: UseQueryResult;
  goNext: () => void;
  goPrevious: () => void;
  setPage: (index: number) => void;
}

const GlobalStartDate = Math.floor(new Date(dayjs().subtract(1, "days")?.toISOString())?.getTime());
const GlobalEndDate = Math.floor(new Date(dayjs()?.toISOString())?.getTime());

const useGetAllWallet = (): IResponse => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParam = queryParams.get("page");

  const filters = useFiltersStore(state => state.selectedFilter);
  // const loginUser = useAppStore((state) => state.loginUser)
  const { meta, goNext, setPage, goPrevious, setupPaginationState } = usePaginationData(1);
  const [data, setData] = useState([]);
  const pageNo = pageParam ? parseInt(pageParam) : meta.pageIndex;

  const { getParams } = useUrlParams();
  const city = getParams("city") || "HYD";

  const urlStartDate = queryParams.get("startDate");
  const urlEndDate = queryParams.get("endDate");

  const startDate = urlStartDate ?? GlobalStartDate;
  const endDate = urlEndDate ?? GlobalEndDate;

  const payload: any = {
    limit: meta.limit,
    page: pageNo,
    startDate,
    endDate,
    city,
    sortType: "desc",
    ...filters,
  };

  if (city === "all") {
    delete payload["city"];
  }

  if (queryParams.get("id")) {
    payload["id"] = queryParams.get("id");
  }

  const onSuccess = (response: AxiosResponse<ResponseType>) => {
    const { data } = response;
    if (data?.data && data.data?.length > 0) {
      // TODO: need to modify it to class based instance
      setData(data.data);
      setupPaginationState(500, pageNo || 1);
    } else {
      setData([]);
      setupPaginationState(0, 0);
    }
  };

  const query = getQueryFromObj(payload);

  const { ...rest } = useQuery([query, query], () => getAllWallets(query), {
    // enabled: !!payload.city,
    onSuccess: onSuccess,
  });

  return { data, meta, rest, goPrevious, setPage, goNext };
};

const usePaymentData = ({ orderId, onSuccess }: { orderId?: string; onSuccess?: any }) => {
  return useQuery([`pay-outstanding-${orderId}`], () => getPaymentsFromOrderId(objectToQueryString({ orderId })), {
    enabled: !!orderId,
    onSuccess: onSuccess ? onSuccess : voidFunction,
  });
};

const useCaseTransactionsData = ({ orderId, onSuccess }: { orderId?: string; onSuccess?: any }) => {
  return useQuery([`pay-dddoutstanding-${orderId}`], () => getTransactionsFroOrderId(objectToQueryString({ orderId })), {
    enabled: !!orderId,
    onSuccess: onSuccess,
  });
};

const useGetTransactionsForWallet = ({ walletId }: { walletId?: string }): IResponse => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParam = queryParams.get("page");

  // const loginUser = useAppStore((state) => state.loginUser)
  const { meta, goNext, setPage, goPrevious, setupPaginationState } = usePaginationData(1, 100);
  const [data, setData] = useState([]);
  const pageNo = pageParam ? parseInt(pageParam) : meta.pageIndex;

  const urlStartDate = queryParams.get("startDate");
  const urlEndDate = queryParams.get("endDate");

  const startDate = urlStartDate ?? GlobalStartDate;
  const endDate = urlEndDate ?? GlobalEndDate;

  const payload: any = {
    limit: meta.limit,
    page: pageNo,
    sortType: "desc",
    sortBy: "desc",
    startDate,
    endDate,
    walletId: walletId || "",
  };

  const onSuccess = (response: AxiosResponse<ResponseType>) => {
    const { data } = response;
    function mapperFn(itm: any) {
      const { name, status } = itm;

      const isDebit = status === "paid";
      const obj = {
        ...itm,
        amount_paid_by: isDebit ? name : "",
        amount_paid_to: isDebit ? "" : name,
      };
      return obj;
    }
    if (data && data?.length > 0) {
      const modData = data?.map(mapperFn);
      setData(modData);
      setupPaginationState(3000, pageNo);
    } else {
      setData([]);
      setupPaginationState(0, 0);
    }
  };

  const query = getQueryFromObj(payload);

  const { ...rest } = useQuery([query, query], () => getTransactionsForWallet(query), {
    enabled: !!payload.walletId,
    onSuccess: onSuccess,
  });

  return { data, meta, rest, goPrevious, setPage, goNext };
};

interface IUseGetOutstandingForWallet extends IResponse {
  metaData: any;
}

const useGetOutstandingForWallet = ({ walletId }: { walletId?: string }): IUseGetOutstandingForWallet => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParam = queryParams.get("page");

  // const loginUser = useAppStore((state) => state.loginUser)
  const { meta, goNext, setPage, goPrevious, setupPaginationState } = usePaginationData(1, 100);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const pageNo = pageParam ? parseInt(pageParam) : meta.pageIndex || 1;

  const urlStartDate = queryParams.get("startDate");
  const urlEndDate = queryParams.get("endDate");

  const startDate = urlStartDate ?? GlobalStartDate;
  const endDate = urlEndDate ?? GlobalEndDate;

  const payload: any = {
    limit: meta.limit,
    page: pageNo,
    sortBy: "desc",
    startDate,
    endDate,
    walletId: walletId || "",
  };

  const onSuccess = (response: AxiosResponse<ResponseType>) => {
    const { meta, outstanding }: any = response.data;
    function mapperFn(itm: any) {
      const { name, status } = itm;
      const isDebit = status === "paid";
      const obj = {
        ...itm,
        amount_paid_by: isDebit ? name : "",
        amount_paid_to: isDebit ? "" : name,
      };
      return obj;
    }
    if (outstanding && outstanding?.length > 0) {
      const modData = outstanding?.map(mapperFn);
      setData(modData);
      setMetaData(meta);
      setupPaginationState(3000, pageNo);
    } else {
      setData([]);
      setupPaginationState(0, 0);
    }
  };

  const query = getQueryFromObj(payload);

  const { ...rest } = useQuery([`outstanding-${query}`, query], () => getOutstandingForWallet(query), {
    enabled: !!payload.walletId,
    onSuccess: onSuccess,
  });

  return { data, meta, rest, goPrevious, setPage, goNext, metaData };
};

const useInfiniteWallet = (defaultQuery: object) => {
  const { getFilterQueryString } = useCaseFilter();

  const loginUser = useRedStore(state => state.user);
  const query = getFilterQueryString(defaultQuery);

  return useInfiniteQuery(
    [`my-wallet-transaction-${query}`],
    async ({ pageParam = 1 }) => {
      const data = await getTransactionsForWallet(getFilterQueryString({ page: pageParam, ...defaultQuery }));
      return data;
    },
    {
      enabled: !!loginUser?.dedicatedToClientId,
      getNextPageParam: (lastPage: any, pages) => {
        if (lastPage?.data.length >= 50) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );
};

const useInfiniteWalletOutstanding = (defaultQuery: object) => {
  const { getFilterQueryString } = useCaseFilter();

  const loginUser = useRedStore(state => state.user);
  const query = getFilterQueryString(defaultQuery);

  return useInfiniteQuery(
    [`my-wallet-outstanding-${query}`],
    async ({ pageParam = 1 }) => {
      const data = await getOutstandingForWallet(getFilterQueryString({ page: pageParam, ...defaultQuery }));
      return data;
    },
    {
      enabled: !!loginUser?.dedicatedToClientId,
      getNextPageParam: (lastPage: any, pages) => {
        if (lastPage?.data.length >= 50) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );
};

const useGeneratePaymentOtp = () => {
  const { mutateAsync, ...rest } = useSyncMutation(generatePaymentOtp);
  const { mutateAsync: verifyOtpAndPay, ...otpVerifyStatus } = useSyncMutation(verifyOtpAndMakePayment);
  const user = useRedStore(state => state.user);
  const selectedOutstanding = useRedStore(state => state.selectedOutstanding);

  const sendOtp = async (payload: any) => {
    const _payload = { ...payload };
    try {
      _payload["debitorId"] = user.walletId;
      _payload["transitionId"] = selectedOutstanding?.map(itm => itm.transactionId);
      const response: any = await mutateAsync(_payload);
      if (response?.data) {
        return response?.data;
      } else {
        showErrorToast("No response found.");
        return { expiryTimeInSecs: 0, otpToken: "" };
      }
    } catch (err) {
      showErrorToast("Unable to Send OTP to this user.");
      logger("COLLECTION AGENT SELECT", err, "error");
      throw Error("Unable to generate Payload for Payment OTP.");
    }
  };

  const verifyOtp = async (payload: any) => {
    const _payload = { ...payload };

    _payload["debitorId"] = user.walletId;
    _payload["transactions"] = selectedOutstanding?.map(itm => itm.transactionId);
    const response: any = await verifyOtpAndPay(_payload);
    if (response?.data) {
      return response?.data;
    }
  };

  return { sendOtp, verifyOtp, otpVerifyStatus, ...rest };
};

const useUnsettleCase = ({ onSuccess }: { onSuccess: any }) => {
  const { mutateAsync, ...rest } = useSyncMutation(linkForUnsettledCases, {
    onSuccess: onSuccess,
  });
  const user = useRedStore(state => state.user);
  const selectedOutstanding = useRedStore(state => state.selectedOutstanding);

  const payload: any = {
    partnerId: null,
    amount: 0,
    cases: [],
    platform: "HM-APP",
    payer: MAPPING_FOR_USER_ROLES_FOR_ONLINE_PAY[user?.primaryRole || ""],
    meta: {
      amountType: "FULL",
    },
  };

  const redPay = async () => {
    try {
      payload["partnerId"] = user.id;
      payload["cases"] = selectedOutstanding?.map(itm => itm.transactionId);
      payload["amount"] = selectedOutstanding?.reduce((acc, itm) => acc + itm.amount, 0);

      if (payload["cases"].length === 0 || payload["amount"] <= 0) {
        showErrorToast("No case selected for payment.");
        return;
      }

      const response: any = await mutateAsync(payload);
      if (response?.data) {
        return response?.data;
      } else {
        showErrorToast("No response found.");
        return { expiryTimeInSecs: 0, otpToken: "" };
      }
    } catch (err) {
      showErrorToast("Unable to Send OTP to this user.");
      throw Error("Unable to generate Payload for Payment OTP.");
    }
  };

  return { redPay, ...rest };
};

export {
  useGetAllWallet,
  usePaymentData,
  useCaseTransactionsData,
  useGetTransactionsForWallet,
  useGetOutstandingForWallet,
  useInfiniteWallet,
  useInfiniteWalletOutstanding,
  useGeneratePaymentOtp,
  useUnsettleCase,
};
